const ENV_LOCAL = 'local';
const ENV_DEV = 'dev';
export const ENV_PRODUCTION = 'production';
const ENV_CYBERDYNE = 'cyberdyne';
const ENV_CONNOR = 'connor';
const ENV_CHOPPER = 'chopper';
const ENV_PLASTIQUE = 'plastique';

export function getSlabEnv() {
  switch (location.origin) {
    case 'http://localhost:8080':
      return ENV_LOCAL;

    case 'https://record.dev.steplab.xyz':
      return ENV_DEV;

    case 'https://app.cyberdyne.steplab.xyz':
      return ENV_CYBERDYNE;

    case 'https://app.connor.steplab.xyz':
      return ENV_CONNOR;

    case 'https://app.chopper.steplab.xyz':
      return ENV_CHOPPER;

    case 'https://app.plastique.steplab.xyz':
      return ENV_PLASTIQUE;

    case 'https://record.app.steplab.co':
      return ENV_PRODUCTION;

    case 'https://record.aus.steplab.co':
      return ENV_PRODUCTION;

    case 'https://app.steplab.co':
      return ENV_PRODUCTION;

    default:
      return ENV_LOCAL;
  }
}

export function getSlabRegion() {
  if (location.origin.includes('record.aus.')) {
    return 'ap-southeast-2';
  } else {
    return 'eu-west-2';
  }
}

export function getConfigValue(key) {
  const configMap = {
    // should include trailing slash:
    API_BASE_URL: {
      local: {
        'eu-west-2': `http://${location.hostname}:8008/`,
        'ap-southeast-2': `http://${location.hostname}:8008/`,
      },
      dev: {
        'eu-west-2': 'https://dev.steplab.xyz/',
        'ap-southeast-2': 'https://dev.steplab.xyz/',
      },
      cyberdyne: {
        'eu-west-2': 'https://cyberdyne.steplab.xyz/',
        'ap-southeast-2': 'https://cyberdyne.steplab.xyz/',
      },
      connor: {
        'eu-west-2': 'https://connor.steplab.xyz/',
        'ap-southeast-2': 'https://connor.steplab.xyz/',
      },
      chopper: {
        'eu-west-2': 'https://chopper.steplab.xyz/',
        'ap-southeast-2': 'https://chopper.steplab.xyz/',
      },
      plastique: {
        'eu-west-2': 'https://plastique.steplab.xyz/',
        'ap-southeast-2': 'https://plastique.steplab.xyz/',
      },
      production: {
        'eu-west-2': 'https://app.steplab.co/',
        'ap-southeast-2': 'https://aus.steplab.co/',
      },
    },
  };

  return configMap[key][getSlabEnv()][getSlabRegion()];
}
