
import BaseLoadingIndicator from '@/components/BaseLoadingIndicator.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LoadingView',
  components: {
    BaseLoadingIndicator,
  },
});
