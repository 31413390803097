<template>
  <logo-bar />
  <router-view />
</template>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: auto;
}

body {
  background-color: #f6f6f6;
}
</style>
<script>
import LogoBar from '@/components/LogoBar';

export default {
  components: { LogoBar },
};
</script>
