<template>
  <div
    v-if="isProbablySafari()"
    class="flex items-center gap-x-6 bg-slate px-6 py-2.5 sm:px-3.5 sm:before:flex-1"
  >
    <p class="text-sm leading-6 text-white">
      To ensure a smooth upload, please try to keep this page open until the
      upload has completed.
    </p>
    <div class="flex flex-1 justify-end"></div>
  </div>
  <div
    v-if="!tokenId"
    class="m-auto flex h-full w-11/12 flex-col justify-between lg:w-6/12"
  >
    <BaseContentCard>
      <p>{{ videoUploadTokenInvalid }}</p>
    </BaseContentCard>
  </div>

  <div v-if="tokenId">
    <div class="card-container">
      <BaseContentCard v-if="!uploadFinished">
        <div class="m-auto flex w-full">
          <base-loading-indicator />
        </div>

        <progress-bar
          :activity-word="'Uploading video'"
          :progress-percentage="completedPercentage"
        />
      </BaseContentCard>

      <BaseContentCard v-if="uploadFinished">
        <CheckCircleIcon class="m-auto w-24 text-blue" />

        <p class="mb-2 text-center font-bold">
          Success! Your recording has uploaded to Steplab.
        </p>

        <p class="mb-2 text-center"></p>

        <p class="mb-2 text-center">
          You can access all your videos from your home page. You can now safely
          close this page or record another video.
        </p>

        <div class="mb-4 mt-6 text-center">
          <router-link
            :to="{
              name: 'VideoRecordLandingView',
              query: {
                token: this.$route.query.token,
                context: this.$route.query.context,
                nb: this.$route.query.nb,
              },
            }"
          >
            <base-button
              class="border border-dark-grey bg-white text-dark-grey"
            >
              <template v-slot:text>Record more</template>
            </base-button>
          </router-link>
        </div>
      </BaseContentCard>
    </div>
  </div>
</template>

<script>
import { Upload } from 'tus-js-client';
import localforage from 'localforage';
import BaseContentCard from '@/components/BaseContentCard.vue';
import { CheckCircleIcon } from '@heroicons/vue/24/solid';
import axios from 'axios';
import getBlobDuration from 'get-blob-duration';
import ProgressBar from '@/components/ProgressBar.vue';
import { defineComponent } from 'vue';
import BaseButton from '@/components/BaseButton';
import BaseLoadingIndicator from '@/components/BaseLoadingIndicator';
import { ENV_PRODUCTION, getConfigValue, getSlabEnv } from '@/config/config';
import { v4 as uuidv4 } from 'uuid';
import logDiagnosticData from '@/helpers/debug';

export default defineComponent({
  name: 'UploadRecordingView',
  components: {
    BaseLoadingIndicator,
    BaseButton,
    ProgressBar,
    BaseContentCard,
    CheckCircleIcon,
  },

  beforeMount() {
    this.blobUrl = decodeURIComponent(this.$route.params.blobUrl);
    if (!this.blobUrl) {
      alert('no blob id param');
    }

    this.tokenId = this.$route.query.token;

    this.uploadType = this.$route.query.uploadType;
  },

  mounted() {
    this.interval = setInterval(this.checkFailedUpload, 5000);
    this.startUpload();
  },

  data() {
    return {
      blobUrl: null,
      completedPercentage: 0,
      tokenId: null,
      uploadFinished: false,
      uploadType: 'unset',
      uploadAttempt: 0,
      erroredAt: null,
      interval: null,
      errorCount: 0,
      diagnostics: {},
    };
  },

  beforeRouteLeave(to, from, next) {
    // If the form is dirty and the user did not confirm leave,
    // prevent losing unsaved changes by canceling navigation

    if (!this.uploadFinished) {
      // Navigate to next view
      return next();
    }

    if (this.confirmStayInDirtyForm()) {
      return next(false);
    }

    // Navigate to next view
    return next();
  },

  created() {
    window.addEventListener('beforeunload', this.beforeWindowUnload);
  },

  methods: {
    confirmLeave() {
      return window.confirm(
        'Are you sure you want to go leave before the upload has finished? This will discard your video.'
      );
    },

    confirmStayInDirtyForm() {
      return !this.uploadFinished && !this.confirmLeave();
    },

    beforeWindowUnload(e) {
      if (this.confirmStayInDirtyForm()) {
        // Cancel the event
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = '';
      }
    },

    checkFailedUpload() {
      if (this.erroredAt && new Date() - this.erroredAt > 5000) {
        console.log('Restarting upload');
        logDiagnosticData(
          'restarting_upload',
          'erroredAt: ' +
            this.erroredAt.toString() +
            ' restarting as a result of error',
          this.tokenId
        );
        this.startUpload();
      }
    },

    async getVideoDurationSeconds(blob) {
      return await getBlobDuration(blob);
    },

    async loadBlobFromUrl(url) {
      return await fetch(url).then((response) => response.blob());
    },

    async returnToLanding(message) {
      if (message) {
        alert(message);
      }

      await this.$router.replace({
        replace: true,
        name: 'VideoRecordLandingView',
        query: {
          token: this.$route.query.token,
          context: this.$route.query.context,
          nb: this.$route.query.nb,
        },
      });
    },

    async loadBlob() {
      console.debug('loadBlob');

      let memoryBlob = null;

      try {
        memoryBlob = await this.loadBlobFromUrl(this.blobUrl);
      } catch (exception) {
        memoryBlob = null;
      }

      return memoryBlob;
    },

    async loadBlobWithCache(blobId) {
      console.debug('loadBlobWithCache');
      let memoryBlob = null;

      try {
        console.log('Loading blob from memory...');
        memoryBlob = await this.loadBlobFromUrl(this.blobUrl);
        console.log('Loaded blob from memory.');
      } catch (exception) {
        console.log('Unable to load blob from memory, trying cached blob...');
      }

      let cachedBlob = await localforage.getItem(blobId);

      if (!cachedBlob && !memoryBlob) {
        console.log('Unable to recover blob. Exiting.');
        await this.$router.replace({
          replace: true,
          name: 'VideoRecordLandingView',
          query: {
            token: this.$route.query.token,
            context: this.$route.query.context,
            nb: this.$route.query.nb,
          },
        });
      }

      return memoryBlob ?? cachedBlob;
    },

    async startUpload() {
      if (this.uploadFinished) {
        return;
      }

      this.uploadAttempt += 1;
      this.erroredAt = null;

      let blobId = this.blobUrl.split('/')[3];
      console.log(`BlobID: ${blobId}`);

      let blob = null;

      console.log(blob);
      if (this.uploadType === 'native') {
        blob = await this.loadBlob();

        if (!blob) {
          return this.returnToLanding(
            'File not found - please select the video from your device again.'
          );
        }
      } else {
        blob = await this.loadBlobWithCache(blobId);
      }

      if (this.uploadType !== 'native') {
        // if the user has selected the video from their device, no need to cache it.
        // if the file isn't already in local storage, save it:
        let cachedBlob = await localforage.getItem(blobId);
        if (!cachedBlob) {
          console.log(`Blob not cached, so caching now. ${blobId}`);
          await localforage.setItem(blobId, blob).catch(function (err) {
            // This code runs if there were any errors
            console.error('unable to save file to store! continuing anyway...');
            console.log(err);
          });
        }
      }

      // Create a new tus upload with the blob
      console.log('Starting TUS upload');
      let upload = new Upload(blob, {
        endpoint: `${axios.defaults.baseURL}content/video/tus?token=${this.tokenId}`,
        retryDelays: [0, 2000, 5000, 10000, 20000, 30000], // delay between retrying this upload
        chunkSize: 52428800, // https://developers.cloudflare.com/stream/uploading-videos/upload-video-file
        uploadDataDuringCreation: false, // not yet supported by Cloudflare
        metadata: {
          filetype: blob.type,
          uploadType: this.uploadType,
          uploadSource: 'UserVideoRecording',
          blobId: blobId,
          uploadAttempt: this.uploadAttempt.toString(),
        },

        onProgress: (bytesUploaded, bytesTotal) => {
          let percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
          this.completedPercentage = parseInt(percentage);
          this.erroredAt = null;
        },

        onError: (err) => {
          logDiagnosticData(
            'tus_upload_request_error',
            `${err.message} - ${err} - request: ${err.originalRequest} - response: ${err.originalResponse}`,
            this.$route.query.token.toString()
          );
          this.errorCount += 1;
          console.log('Error', err);
          console.log('Request', err.originalRequest);
          console.log('Response', err.originalResponse);
          this.erroredAt = Date.now();
        },

        onSuccess: () => {
          logDiagnosticData(
            'tus_upload_success',
            `errorCount: ${this.errorCount.toString()}`,
            this.$route.query.token.toString()
          );
          this.erroredAt = null;
          this.errorCount = 0;
          this.uploadFinished = true;
          clearInterval(this.interval);
          localforage.clear();
          window.removeEventListener('beforeunload', this.beforeWindowUnload);
          this.notifyClientUploadComplete();
        },
      });

      // Check if there are any previous uploads to continue.
      upload.findPreviousUploads().then(function (previousUploads) {
        // Found previous uploads so we select the first one.
        if (previousUploads.length) {
          upload.resumeFromPreviousUpload(previousUploads[0]);
        }

        // Start the upload
        upload.start();
      });
    },

    notifyClientUploadComplete() {
      let token = this.$route.query.token.toString();

      if (!token) {
        return;
      }
      axios.post(`/content/video/record/client-upload-complete/${token}`);
    },
  },
});
</script>
