<template>
  <div>
    <div class="mb-5">
      original:
      <input
        ref="cameraFileInput"
        id="cameraFileInput"
        type="file"
        accept="video/*"
        capture="environment"
        @change="onInputChange"
      />
    </div>

    <div class="mb-5">
      capture false:
      <input
        ref="cameraFileInput"
        id="cameraFileInput"
        type="file"
        accept="video/*"
        capture="false"
        @change="onInputChange"
      />
    </div>

    <div class="mb-5">
      no capture:
      <input
        ref="cameraFileInput"
        id="cameraFileInput"
        type="file"
        accept="video/*"
        @change="onInputChange"
      />
    </div>

    <div class="mb-5">
      no capture accept mime types:
      <input
        ref="cameraFileInput"
        id="cameraFileInput"
        type="file"
        accept="video/mp4,video/x-m4v,video/*"
        @change="onInputChange"
      />
    </div>

    <div class="mb-5">
      just file:
      <input
        ref="cameraFileInput"
        id="cameraFileInput"
        type="file"
        @change="onInputChange"
      />
    </div>

    <div class="mb-5">
      original:
      <input
        ref="cameraFileInput"
        id="cameraFileInput"
        type="file"
        accept="video/*"
        capture="environment"
        @change="onInputChange"
        multiple
      />
    </div>

    <div class="mb-5">
      capture false:
      <input
        ref="cameraFileInput"
        id="cameraFileInput"
        type="file"
        accept="video/*"
        capture="false"
        @change="onInputChange"
        multiple
      />
    </div>

    <div class="mb-5">
      no capture:
      <input
        ref="cameraFileInput"
        id="cameraFileInput"
        type="file"
        accept="video/*"
        @change="onInputChange"
        multiple
      />
    </div>

    <div class="mb-5">
      no capture accept mime types:
      <input
        ref="cameraFileInput"
        id="cameraFileInput"
        type="file"
        accept="video/mp4,video/x-m4v,video/*"
        @change="onInputChange"
        multiple
      />
    </div>

    <div class="mb-5">
      just file:
      <input
        ref="cameraFileInput"
        id="cameraFileInput"
        type="file"
        @change="onInputChange"
        multiple
      />
    </div>

    <div class="mb-5">
      nothing
      <input
        ref="cameraFileInput"
        id="cameraFileInput"
        @change="onInputChange"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SafariInputTestView',
  methods: {
    onInputChange(event) {
      const target = event.target;
      const file = target.files[0];

      console.info(target);
      console.info(file);
      console.info(URL.createObjectURL(file));
    },
  },
};
</script>
