import axios from 'axios';
import axiosRetry from 'axios-retry';

export default async function logDiagnosticData(
  eventName,
  message,
  uploadTokenID
) {
  const timestamp = Date.now();
  axiosRetry(axios, {
    retries: 1000, // number of retries
    retryDelay: (retryCount) => {
      console.debug(`logDiagnosticData request retry attempt: ${retryCount}`);
      return retryCount * 500; // time interval between retries
    },
  });

  axios.put(`${axios.defaults.baseURL}events/log-uvr-diagnostics`, {
    type: 'uvr_diagnostic_log',
    entity_model_id: uploadTokenID,
    json: {
      eventName: eventName,
      message: message,
      uploadTokenID: uploadTokenID,
      timestamp: timestamp,
    },
  });
}
