<template>
  <div
    class="m-auto flex h-full w-full flex-col justify-between px-6 py-10 text-white md:w-2/3 md:px-0 lg:w-1/3 lg:py-12 xl:w-1/4"
  >
    <div>
      <Listbox as="div" v-model="selectedAudioDevice">
        <ListboxLabel class="block font-medium text-white"
          >Microphone</ListboxLabel
        >
        <div class="relative mt-1">
          <ListboxButton
            class="relative w-full cursor-default rounded-md border border-border-grey bg-white py-2 pl-3 pr-10 text-left text-slate shadow-sm focus:border-slate focus:outline-none focus:ring-1 focus:ring-slate sm:text-sm"
          >
            <span class="block truncate">{{ selectedAudioDevice.name }}</span>
            <span
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
            >
              <ChevronUpDownIcon
                class="h-5 w-5 text-slate"
                aria-hidden="true"
              />
            </span>
          </ListboxButton>

          <transition
            leave-active-class="transition ease-in duration-100"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <ListboxOptions
              class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            >
              <ListboxOption
                as="template"
                v-for="device in audioDevices"
                :key="device.id"
                :value="device"
                v-slot="{ active, selected }"
              >
                <li
                  :class="[
                    active ? 'bg-slate text-white' : 'text-slate',
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                  ]"
                >
                  <span
                    :class="[
                      selected ? 'font-semibold' : 'font-normal',
                      'block truncate',
                    ]"
                    >{{ device.name }}</span
                  >

                  <span
                    v-if="selected"
                    :class="[
                      active ? 'text-white' : 'text-slate',
                      'absolute inset-y-0 right-0 flex items-center pr-4',
                    ]"
                  >
                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                  </span>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>

      <Listbox as="div" v-model="selectedVideoDevice" class="mt-6">
        <ListboxLabel class="block font-medium text-white">Camera</ListboxLabel>
        <div class="relative mt-1">
          <ListboxButton
            class="relative w-full cursor-default rounded-md border border-border-grey bg-white py-2 pl-3 pr-10 text-left text-slate shadow-sm focus:border-slate focus:outline-none focus:ring-1 focus:ring-slate sm:text-sm"
          >
            <span class="block truncate">{{ selectedVideoDevice.name }}</span>
            <span
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
            >
              <ChevronUpDownIcon
                class="h-5 w-5 text-slate"
                aria-hidden="true"
              />
            </span>
          </ListboxButton>

          <transition
            leave-active-class="transition ease-in duration-100"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <ListboxOptions
              class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            >
              <ListboxOption
                as="template"
                v-for="device in videoDevices"
                :key="device.id"
                :value="device"
                v-slot="{ active, selected }"
              >
                <li
                  :class="[
                    active ? 'bg-slate text-white' : 'text-slate',
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                  ]"
                >
                  <span
                    :class="[
                      selected ? 'font-semibold' : 'font-normal',
                      'block truncate',
                    ]"
                    >{{ device.name }}</span
                  >

                  <span
                    v-if="selected"
                    :class="[
                      active ? 'text-white' : 'text-slate',
                      'absolute inset-y-0 right-0 flex items-center pr-4',
                    ]"
                  >
                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                  </span>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>
    </div>

    <div class="">
      <XMarkIcon @click="toggleMenu" class="m-auto w-10 cursor-pointer" />
    </div>
  </div>
</template>

<script>
import {
  XMarkIcon,
  CheckIcon,
  ChevronUpDownIcon,
} from '@heroicons/vue/24/solid';
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
  ListboxLabel,
} from '@headlessui/vue';
import store from '@/store';
import localforage from 'localforage';

export default {
  name: 'VideoRecordWebMenuOverlay',
  components: {
    XMarkIcon,
    CheckIcon,
    Listbox,
    ListboxOption,
    ListboxOptions,
    ListboxButton,
    ListboxLabel,
    ChevronUpDownIcon,
  },

  props: {
    audioDevices: Object,
    videoDevices: Object,
  },

  methods: {
    toggleMenu() {
      this.$emit('menuToggle');
    },
  },

  watch: {
    selectedAudioDevice(newValue) {
      store.commit('video/SET_MICROPHONE', newValue);
      // If the user explicitly changes their preferences to use this microphone, persist this preference it in localstorage.
      // We have to save the 'name' of the device rather than the UUID, as in some browsers the UUID changes between sessions:
      localforage.setItem('audioDeviceSavedPreference', newValue.name);
    },

    selectedVideoDevice(newValue) {
      store.commit('video/SET_CAMERA', newValue);
    },
  },

  data() {
    return {
      selectedAudioDevice: this.$store.state.video.selectedAudioDevice,
      selectedVideoDevice: this.$store.state.video.selectedVideoDevice,
    };
  },
};
</script>
