<template>
  <div id="app">
    <dashboard
      ref="dash"
      :uppy="uppy"
      :props="{
        metaFields: [
          { id: 'name', name: 'Name', placeholder: 'file name' },
          {
            id: 'caption',
            name: 'Caption',
            placeholder: 'describe what the image is about',
          },
        ],
      }"
      :plugins="[]"
    />
  </div>
</template>

<script>
import { Dashboard } from '@uppy/vue';

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';

import Uppy from '@uppy/core';
import Tus from '@uppy/tus';
import axios from 'axios';
import { getConfigValue } from '@/config/config';

const uppy = new Uppy({
  allowMultipleUploads: false,
  restrictions: {
    maxNumberOfFiles: 1,
    allowedFileTypes: ['video/*'],
  },
});

const urlParams = new URLSearchParams(window.location.search);
const myParam = urlParams.get('token');

uppy.use(Tus, {
  endpoint: `${getConfigValue(
    'API_BASE_URL'
  )}content/video/tus?token=${myParam}`,
});

export default {
  name: 'App',
  components: {
    Dashboard,
  },

  beforeMount() {
    this.tokenId = this.$route.query.token;
  },

  data: () => {
    return {
      uppy,
      tokenId: null,
    };
  },
  computed: {
    // uppy: () =>
    //   new Uppy().use(XHRUpload, {
    //     endpoint: "http://localhost:3000/files",
    //     fieldName: "file",
    //   }),
  },
  beforeUnmount() {
    this.uppy.close();
  },
};
</script>
