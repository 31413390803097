import { createStore } from 'vuex';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import video from '@/store/video';

export default createStore({
  state: { warnBeforeLeaveMessage: null },
  getters: {},
  mutations: {
    WARN_BEFORE_LEAVE(state, value) {
      state.warnBeforeLeaveMessage = value;
    },

    OK_TO_LEAVE(state) {
      state.warnBeforeLeaveMessage = null;
    },
  },
  actions: {},
  modules: { video },
});
