
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ProgressBar',
  props: {
    activityWord: {
      type: String,
      default: 'Processing',
    },

    progressPercentage: {
      type: Number,
      default: 2,
    },
  },
  computed: {
    widthStyle(): string {
      return `width: ${this.progressPercentage}%`;
    },
  },
});
