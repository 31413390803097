<template>
  <div
    v-if="isProbablySafari()"
    class="flex items-center gap-x-6 bg-slate py-2.5 px-6 sm:px-3.5 sm:before:flex-1"
  >
    <p class="text-sm leading-6 text-white">
      To ensure a smooth upload, please try to keep this page open until the
      upload has completed.
    </p>
    <div class="flex flex-1 justify-end"></div>
  </div>
  <div
    v-if="!tokenId"
    class="m-auto flex h-full w-11/12 flex-col justify-between lg:w-6/12"
  >
    <BaseContentCard>
      <p>{{ videoUploadTokenInvalid }}</p>
    </BaseContentCard>
  </div>

  <div v-if="tokenId">
    <div class="card-container">
      <BaseContentCard v-if="!uploadFinished">
        <div class="m-auto flex w-full">
          <base-loading-indicator />
        </div>

        <progress-bar
          :activity-word="'Uploading video'"
          :progress-percentage="completedPercentage"
        />
      </BaseContentCard>
    </div>
  </div>
</template>

<script>
import localforage from 'localforage';
import BaseContentCard from '@/components/BaseContentCard.vue';
import axios from 'axios';
import getBlobDuration from 'get-blob-duration';
import ProgressBar from '@/components/ProgressBar.vue';
import { defineComponent } from 'vue';
import BaseLoadingIndicator from '@/components/BaseLoadingIndicator';
import logDiagnosticData from '@/helpers/debug';

export default defineComponent({
  name: 'NewUploadRecordingView',
  components: {
    BaseLoadingIndicator,
    ProgressBar,
    BaseContentCard,
  },

  beforeMount() {
    this.blobUrl = decodeURIComponent(this.$route.params.blobUrl);
    if (!this.blobUrl) {
      alert('no blob id param');
    }

    this.tokenId = this.$route.query.token;

    this.uploadType = this.$route.query.uploadType;
  },

  mounted() {
    this.interval = setInterval(this.checkFailedUpload, 5000);
    this.startUpload();
  },

  data() {
    return {
      blobUrl: null,
      completedPercentage: 0,
      tokenId: null,
      uploadFinished: false,
      uploadType: 'unset',
      uploadAttempt: 0,
      erroredAt: null,
      interval: null,
      errorCount: 0,
      diagnostics: {},
    };
  },

  beforeRouteLeave(to, from, next) {
    // If the form is dirty and the user did not confirm leave,
    // prevent losing unsaved changes by canceling navigation

    if (!this.uploadFinished) {
      // Navigate to next view
      return next();
    }

    if (this.confirmStayInDirtyForm()) {
      return next(false);
    }

    // Navigate to next view
    return next();
  },

  created() {
    window.addEventListener('beforeunload', this.beforeWindowUnload);
  },

  methods: {
    confirmLeave() {
      return window.confirm(
        'Are you sure you want to go leave before the upload has finished? This will discard your video.'
      );
    },

    confirmStayInDirtyForm() {
      return !this.uploadFinished && !this.confirmLeave();
    },

    beforeWindowUnload(e) {
      if (this.confirmStayInDirtyForm()) {
        // Cancel the event
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = '';
      }
    },

    checkFailedUpload() {
      if (this.erroredAt && new Date() - this.erroredAt > 5000) {
        console.log('Restarting upload');
        logDiagnosticData(
          'restarting_upload',
          'erroredAt: ' +
            this.erroredAt.toString() +
            ' restarting as a result of error',
          this.tokenId
        );
        this.startUpload();
      }
    },

    async getVideoDurationSeconds(blob) {
      return await getBlobDuration(blob);
    },

    async loadBlobFromUrl(url) {
      return await fetch(url).then((response) => response.blob());
    },

    async loadBlob() {
      console.debug('loadBlob');

      let memoryBlob = null;

      try {
        memoryBlob = await this.loadBlobFromUrl(this.blobUrl);
      } catch (exception) {
        memoryBlob = null;
      }

      return memoryBlob;
    },

    async loadBlobWithCache(blobId) {
      console.debug('loadBlobWithCache');
      let memoryBlob = null;

      try {
        console.log('Loading blob from memory...');
        memoryBlob = await this.loadBlobFromUrl(this.blobUrl);
        console.log('Loaded blob from memory.');
      } catch (exception) {
        console.log('Unable to load blob from memory, trying cached blob...');
      }

      let cachedBlob = await localforage.getItem(blobId);

      if (!cachedBlob && !memoryBlob) {
        console.log('Unable to recover blob. Exiting.');
        await this.$router.replace({
          replace: true,
          name: 'VideoRecordLandingView',
          query: {
            token: this.$route.query.token,
            context: this.$route.query.context,
            nb: this.$route.query.nb,
          },
        });
      }

      return memoryBlob ?? cachedBlob;
    },

    getBlobFileExtension(blob) {
      console.log(blob.type.toString());
      logDiagnosticData(
        's3_record_upload_get_file_extension',
        `${navigator.userAgent} - ${blob.type.toString()}`,
        this.$route.query.token.toString()
      );

      if (blob.type.toString().includes('x-matroska')) {
        return 'mkv';
      }

      return 'mp4';
    },

    async returnToLanding(message) {
      if (message) {
        alert(message);
      }

      await this.$router.replace({
        replace: true,
        name: 'VideoRecordLandingView',
        query: {
          token: this.$route.query.token,
          context: this.$route.query.context,
          nb: this.$route.query.nb,
        },
      });
    },

    async getUploadData(numberOfParts, blob) {
      return await fetch(
        `${axios.defaults.baseURL}api/internal/record/create-upload`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            file_extension: this.getBlobFileExtension(blob),
            number_of_parts: numberOfParts,
            upload_token: this.tokenId,
            source: 'web',
          }),
        }
      ).then((response) => response.json());
    },

    async startUpload() {
      if (this.uploadFinished) {
        return;
      }

      logDiagnosticData(
        's3_record_start_upload',
        `uploadType: ${
          this.uploadType
        } - uploadAttempt: ${this.uploadAttempt.toString()}`,
        this.$route.query.token.toString()
      );

      this.uploadAttempt += 1;
      this.erroredAt = null;

      // 1. Load blob
      let blobId = this.blobUrl.split('/')[3];
      let blob = null;

      console.debug(blob);

      if (this.uploadType === 'native') {
        blob = await this.loadBlob();

        if (!blob) {
          return this.returnToLanding(
            'File not found - please select the video from your device again.'
          );
        }
      } else {
        blob = await this.loadBlobWithCache(blobId);
      }

      // 2. Split the file into parts and upload each part
      let partSize = 8 * 1024 * 1024; // 8MB in binary
      const parts = Math.ceil(blob.size / partSize);
      const uploadResults = [];

      // 3. Call getUploadData to get the pre-signed URLs and complete URL
      const uploadData = await this.getUploadData(parts, blob);

      let uploadURLs = uploadData['upload_urls'];
      let completeURL = uploadData['complete_url'];

      for (let i = 0; i < parts; i++) {
        try {
          const start = i * partSize;
          const end =
            start + partSize < blob.size ? start + partSize : blob.size;
          const filePart = blob.slice(start, end);

          // 4. Upload the part to S3
          const partNumber = uploadURLs[i]['part_number'];

          console.debug('current part number:', partNumber);
          console.debug('number of parts:', parts);

          const uploadResponse = await fetch(uploadURLs[i]['url'], {
            method: 'PUT',
            body: filePart,
            headers: {
              'Content-Type': 'application/octet-stream',
            },
          });

          if (!uploadResponse.ok) {
            throw new Error(`Failed to upload part ${partNumber}`);
          }

          // Store the ETag for completing the upload
          uploadResults.push({
            PartNumber: partNumber,
            ETag: uploadResponse.headers.get('ETag'),
          });

          this.completedPercentage = Math.min(
            Math.round((partNumber / parts) * 100),
            100
          );

          console.debug('Uploaded part', partNumber, 'successfully');
          console.debug('percent:', this.completedPercentage);

          this.erroredAt = null;
        } catch (err) {
          logDiagnosticData(
            's3_record_upload_error',
            `${err.message} - ${err} - request: ${err.originalRequest} - response: ${err.originalResponse}`,
            this.$route.query.token.toString()
          );
          this.errorCount += 1;
          console.error('Error', err);
          console.error('Request', err.originalRequest);
          console.error('Response', err.originalResponse);
          this.erroredAt = Date.now();
        }
      }

      // 5. Complete the upload
      const completeXML = `<CompleteMultipartUpload>${uploadResults
        .map(
          (part) => `
  <Part>
    <PartNumber>${part.PartNumber}</PartNumber>
    <ETag>${part.ETag}</ETag>
  </Part>`
        )
        .join('')}
</CompleteMultipartUpload>`;

      const completeResponse = await fetch(completeURL, {
        method: 'POST',
        body: completeXML,
        headers: {
          'Content-Type': 'application/xml',
        },
      });

      if (!completeResponse.ok) {
        const text = await completeResponse.text();
        throw new Error(`Failed to complete the upload: ${text}`);
      }

      console.debug('Upload completed successfully');

      logDiagnosticData(
        's3_record_upload_success',
        `errorCount: ${this.errorCount.toString()}`,
        this.$route.query.token.toString()
      );
      this.erroredAt = null;
      this.errorCount = 0;
      this.uploadFinished = true;
      clearInterval(this.interval);
      localforage.clear();
      window.removeEventListener('beforeunload', this.beforeWindowUnload);

      await this.$router.replace({
        replace: true,
        name: 'UploadCompleteView',
        query: {
          token: this.$route.query.token,
          context: this.$route.query.context,
          nb: this.$route.query.nb,
        },
      });
    },
  },
});
</script>
