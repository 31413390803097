<template>
  <div class="flex w-10 items-center font-lato">
    <img class="ml-5 w-6" src="@/assets/steplab_logo.png" />
    <span class="ml-2 text-2xl font-black tracking-tight text-slate"
      >Steplab</span
    >
  </div>
</template>

<script>
export default {
  name: 'SteplabLogo',
};
</script>
