<template>
  <div class="m-auto flex w-full">
    <base-loading-indicator v-if="isLoading" />
  </div>

  <div
    v-if="!isLoading && !tokenValid"
    class="m-auto flex h-full w-11/12 flex-col justify-between text-slate lg:w-6/12"
  >
    <BaseContentCard>
      <div class="bg-yellow-50 rounded-md p-4">
        <div class="flex">
          <div class="flex-shrink-0">
            <ExclamationTriangleIcon
              class="h-5 w-5 text-yellow"
              aria-hidden="true"
            />
          </div>
          <div class="ml-3">
            <h3 class="text-lg">Sorry, something went wrong</h3>
            <div class="mt-2">
              <p>{{ tokenInvalidUserFacingmessage }}</p>
            </div>
          </div>
        </div>
      </div>
    </BaseContentCard>
  </div>

  <div
    v-if="!isLoading && tokenValid && !happyToShowCamera"
    class="m-auto flex h-full w-11/12 flex-col justify-between text-slate lg:w-6/12"
  >
    <BaseContentCard>
      <div class="bg-yellow-50 md:p4 rounded-md p-1">
        <div class="flex">
          <div class="">
            <div class="mt-2">
              <p class="mb-4">
                For uploading videos, we recommend using the free Steplab Record app.
              </p>
              <div class="mt-4 flex flex-col">
                <router-link
                  class="m-auto"
                  :to="{
                    name: 'VideoRecordLandingView',
                    query: {
                      token: this.$route.query.token,
                      context: this.$route.query.context,
                      nb: this.$route.query.nb,
                    },
                  }"
                >
                  <base-button class="m-auto bg-blue">
                    <template v-slot:text
                      ><span class="text-lg"> Go back </span></template
                    >
                  </base-button>
                </router-link>

                <div
                  @click="this.happyToShowCamera = true"
                  class="m-auto mt-4 text-center underline"
                >
                  Continue anyway
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseContentCard>
  </div>

  <div v-if="happyToShowCamera" id="container">
    <div
      :class="{
        videoContainerMobile: isProbablyMobile(),
        videoContainerDesktop: !isProbablyMobile(),
      }"
    >
      <video
        v-if="!outputBlobUrl"
        class="video"
        muted
        :srcObject="videoSrcObject"
        autoplay
        playsinline
      ></video>

      <video
        class="video"
        controls
        v-if="outputBlobUrl"
        :src="outputBlobUrl"
        playsinline
      ></video>

      <div
        class="fixed bottom-0 left-0 right-0 top-0 z-10 hidden h-full w-full bg-light-grey"
        id="video_overlay"
      ></div>
    </div>

    <div
      class="absolute left-1/2 top-3 z-30 m-auto h-full -translate-x-1/2 text-white"
      v-if="isRecording"
    >
      <span class="rounded bg-red p-1">
        {{ duration }}
      </span>
    </div>

    <div
      :class="{
        videoContainerMobile: isProbablyMobile(),
        videoContainerDesktop: !isProbablyMobile(),
      }"
      v-if="showMenuOverlay"
      class="fixed bottom-0 left-0 right-0 top-0 z-50 h-full w-full bg-dark-grey opacity-95"
    >
      <VideoRecordWebMenuOverlay
        @menuToggle="toggleMenu"
        :audioDevices="availableDevices['audio']"
        :videoDevices="availableDevices['video']"
      />
    </div>

    <VideoRecordWebNotification
      v-if="showNotification && !isRecording && !outputBlobUrl"
      :notificationMessage="notificationMessage"
    />

    <div
      v-if="!showMenuOverlay"
      :class="{
        'z-50': true,
        dynamicVideoControlBarMobile: isProbablyMobile(),
        dynamicVideoControlBarDesktop: !isProbablyMobile(),
      }"
    >
      <div v-if="!outputBlobUrl" class="w-1/5">
        <XMarkIcon @click="exit()" class="m-auto w-6 cursor-pointer" />
      </div>

      <record-video-button
        v-if="!outputBlobUrl"
        v-on:click="toggleRecording"
        v-bind:is-recording="isRecording"
      />

      <div v-if="!outputBlobUrl && !isRecording" class="w-1/5">
        <EllipsisVerticalIcon
          @click="toggleMenu()"
          class="m-auto w-7 cursor-pointer"
        />
      </div>

      <!--    Controls to show if the video HAS been recorded:-->
      <div class="w-1/5" v-if="outputBlobUrl">
        <TrashIcon @click="exit()" class="m-auto w-6 cursor-pointer" />
      </div>

      <base-button-with-icon
        @click="upload"
        color="blue"
        class="w-40 bg-blue"
        v-if="outputBlobUrl"
      >
        <template v-slot:text> Upload video</template>

        <template v-slot:icon>
          <CheckIcon />
        </template>
      </base-button-with-icon>

      <div class="w-1/5" v-if="outputBlobUrl"></div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import RecordVideoButton from '@/components/RecordVideoButton.vue';
import BaseButtonWithIcon from '@/components/BaseButtonWithIcon.vue';
import BaseLoadingIndicator from '@/components/BaseLoadingIndicator.vue';
import {
  TrashIcon,
  CheckIcon,
  XMarkIcon,
  EllipsisVerticalIcon,
  ExclamationTriangleIcon,
} from '@heroicons/vue/24/solid';

import router from '@/router';
import screenfull from 'screenfull';

import { defineComponent } from 'vue';
import durationHuman from '@/helpers/time';
import localforage from 'localforage';
import BaseContentCard from '@/components/BaseContentCard';
import axios from 'axios';
import { ENV_PRODUCTION, getConfigValue, getSlabEnv } from '@/config/config';
import VideoRecordWebMenuOverlay from '@/components/VideoRecordWebMenuOverlay';
import VideoRecordWebNotification from '@/components/VideoRecordWebNotification';
import store from '@/store';
import logDiagnosticData from '@/helpers/debug';
import callWithRetry from '@/helpers/async';
import { isProbablyiOS, isProbablyMobile } from '@/helpers/device';
import BaseButton from '@/components/BaseButton.vue';

export default defineComponent({
  name: 'VideoRecordingView',
  components: {
    BaseButton,
    VideoRecordWebNotification,
    ExclamationTriangleIcon,
    VideoRecordWebMenuOverlay,
    RecordVideoButton,
    TrashIcon,
    BaseButtonWithIcon,
    XMarkIcon,
    EllipsisVerticalIcon,
    CheckIcon,
    BaseContentCard,
    BaseLoadingIndicator,
  },

  data() {
    return {
      tokenValid: false,
      tokenInvalidUserFacingmessage: this.text('videoUploadTokenInvalid'),
      isLoading: true,
      happyToShowCamera: false,
      availableDevices: { video: [], audio: [] },
      facingMode: 'environment',
      isRecording: false,
      videoSrcObject: null,
      mediaRecorder: null,
      blobsRecorded: [],
      outputBlob: null,
      outputBlobUrl: null,
      durationSeconds: 0,
      showMenuOverlay: false,
      showNotification: false,
      notificationTimeouts: [],
      notificationMessage: '',
    };
  },

  watch: {
    // The 'watch' object will define which properties we should listen on, and take action when they change.
    selectedAudioDevice(newValue) {
      this.changeInputDeviceOnStream(newValue, 'audio');
      // We want to display a ribbon at the top to indicate which mic is being used:
      this.displayNotification(this.renderMicrophoneMessage(newValue.name));
    },

    selectedVideoDevice(newValue) {
      // Another Safari workaround! When you change the input device on the stream initially, it causes the camera to zoom in...
      // So this means we only change camera when someone asks to after the initial video render.
      if (this.happyToShowCamera) {
        this.changeInputDeviceOnStream(newValue, 'video');
      }
    },
  },

  computed: {
    selectedAudioDevice() {
      return this.$store.state.video.selectedAudioDevice;
    },

    selectedVideoDevice() {
      return this.$store.state.video.selectedVideoDevice;
    },

    duration() {
      return durationHuman(this.durationSeconds);
    },

    baseConstraints() {
      let constraints = {
        audio: {},
        video: {
          facingMode: 'environment',
        },
      };

      if (
        (this.isProbablyMobile() && this.isProbablyiOS()) ||
        !this.isProbablyMobile()
      ) {
        constraints['video']['width'] = { min: 300, ideal: 1920, max: 1920 };
        constraints['video']['height'] = { min: 300, ideal: 1080, max: 1080 };
      }

      return constraints;
    },
  },

  beforeMount() {
    if (this.$route.query.token === null || !this.$route.query.token) {
      this.tokenValid = false;
      this.isLoading = false;
      return;
    }
    store.commit('OK_TO_LEAVE');
    this.validateToken(this.$route.query.token.toString());
  },

  mounted() {
    if (screenfull.isEnabled && screenfull.isFullscreen) {
      screenfull.exit();
    }

    this.showCamera();
  },

  beforeUnmount() {
    window.removeEventListener('beforeunload', this.beforeWindowUnload);

    // reset background colour:
    document
      .querySelector('body')
      .setAttribute('style', 'background-color:#F6F6F6');
  },

  created() {
    window.addEventListener('beforeunload', this.beforeWindowUnload);
  },

  methods: {
    isProbablyiOS,
    isProbablyMobile,
    validateToken(token) {
      axios
        .get(`/content/video/record/validate-token/${token}`)
        .then(() => {
          this.tokenValid = true;
        })
        .catch((error) => {
          this.tokenValid = false;

          if (
            error.toString().includes('Network Error') &&
            getSlabEnv() !== ENV_PRODUCTION
          ) {
            return (this.tokenInvalidUserFacingmessage = `Unable to connect to the server ${getConfigValue(
              'API_BASE_URL'
            )}. If you are on a different network to your ususal one, please speak to Steplab tech support to ensure this network is authorised to upload videos.`);
          }

          if (
            error.response.data.data.message &&
            error.response.data.data.message === 'UNSUPPORTED_DEVICE'
          ) {
            return (this.tokenInvalidUserFacingmessage =
              this.text('unsupportedDevice'));
          }

          if (
            error.response.data.data.message &&
            error.response.data.data.message === 'WEBVIEW_DETECTED'
          ) {
            return (this.tokenInvalidUserFacingmessage =
              this.text('webviewDetected'));
          }
        })
        .finally(() => {
          this.isLoading = true;
        });
    },

    async showCamera() {
      this.isLoading = true;

      if (navigator.mediaDevices === undefined || !navigator.mediaDevices) {
        if (location.protocol !== 'https:') {
          await alert(
            "Error - it looks like you aren't on a https connection - video features will not work."
          );
        } else {
          await alert(this.text('unsupportedDevice'));
        }
        this.isLoading = false;
      }

      if (!this.isLoading) {
        return false;
      }

      await navigator.mediaDevices
        .getUserMedia(this.baseConstraints)
        .then((stream) => {
          this.videoSrcObject = stream;
        })
        .catch((error) => {
          let message =
            'Oops - it looks like we were unable to access the camera on your device. Did you give Steplab permission to access your camera and microphone?';

          if (error.toString().includes('NotFoundError')) {
            message =
              'Oops - we were unable to find a camera on your device. Please ensure you are using a device that has a camera. If you think this is an error, please get in touch with us at hello@steplab.co';
          }

          alert(message);
          this.isLoading = false;
        });

      if (!this.isLoading) {
        return false;
      }

      let currentAudioTrack = this.videoSrcObject.getAudioTracks()[0];
      let audioTrackDeviceID = currentAudioTrack
        ? currentAudioTrack.getSettings().deviceId
        : null;

      let currentVideoTrack = this.videoSrcObject.getVideoTracks()[0];
      let videoTrackDeviceID = currentVideoTrack
        ? currentVideoTrack.getSettings().deviceId
        : null;

      let audioDeviceSavedPreference = await localforage.getItem(
        'audioDeviceSavedPreference'
      );

      const knownDeviceString = 'USBAudio';

      let devicesToRegister = {
        audio: [],
        video: [],
      };
      await navigator.mediaDevices.enumerateDevices().then((devices) => {
        // Here we assign each device a priority. Lower is higher priority:
        devices.forEach(function (device) {
          let priority = null;
          const deviceFormatted = {
            name: device.label,
            id: device.deviceId,
            kind: device.kind,
            priority: 100,
          };

          if (deviceFormatted.kind === 'audioinput') {
            // If the user has a saved audio device as their preference, set this highest priority:
            if (
              audioDeviceSavedPreference &&
              audioDeviceSavedPreference === device.label
            ) {
              priority = 1;
            }

            // If the device name is one that we know or recommend, push this as second priority
            else if (
              device.label
                .toLowerCase()
                .includes(knownDeviceString.toLowerCase())
            ) {
              priority = 2;
            }

            // Otherwise find the audio device that the browser picked as the one to use, and set this as the default:
            else if (device.deviceId === audioTrackDeviceID) {
              priority = 3;
            } else {
              priority = 100;
            }

            deviceFormatted['priority'] = priority;
            devicesToRegister['audio'].push(deviceFormatted);
            console.log('found device: ', deviceFormatted);
          }

          if (deviceFormatted.kind === 'videoinput') {
            // Find the video device that the browser picked as the one to use, and set this as the default:
            if (device.deviceId === videoTrackDeviceID) {
              priority = 2;
            }
            deviceFormatted['priority'] = priority ? priority : 100;
            devicesToRegister['video'].push(deviceFormatted);
            console.log('found device: ', deviceFormatted);
          }
        });
      });

      devicesToRegister['audio'].sort((a, b) => a.priority - b.priority);
      devicesToRegister['video'].sort((a, b) => a.priority - b.priority);

      this.availableDevices = devicesToRegister;
      // Save the microphone and camera choices in global state, so they can be used by other components:
      await store.commit('video/SET_MICROPHONE', devicesToRegister['audio'][0]);
      await store.commit('video/SET_CAMERA', devicesToRegister['video'][0]);

      // There's a race condition where you can click record before the media stream is ready.
      // This function will check if the stream is ready before showing the camera UI.
      // (There is an event emitted when the stream is ready, but it's not supported by all browsers.)
      await callWithRetry(() => {
        if (!this.videoSrcObject || !this.videoSrcObject.active) {
          console.debug('video stream not active');
          throw new Error('Video stream not active');
        }
        console.debug('video stream active');
        console.debug('ready to show camera');

        if (!isProbablyiOS()) {
          setTimeout(() => (this.happyToShowCamera = true), 2000);
          setTimeout(() => (this.isLoading = false), 2000);
        } else {
          this.isLoading = false;
        }
      });
    },

    async changeInputDeviceOnStream(changedTo, type) {
      // Changes the device being used on the media stream. This function should be called downstream from the device changing in state.
      // Currently doesn't support changing while recording is in progress, so users are prevented from calling this after recording has started. But this functionality could be added in future.

      // Stop current media tracks so we can switch out mic input:
      await this.stopTracks();

      // The new media object should inherit the base constraints for this device:
      let constraints = this.baseConstraints;

      // Specify the ID of the device to switch to in the constraints object:
      // 'type' should be 'audio' or 'video'.
      constraints[type]['deviceId'] = { exact: changedTo['id'] };

      await navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
        this.videoSrcObject = stream;
      });
    },

    startRecording() {
      logDiagnosticData(
        'start_recording_requested',
        'OK',
        this.$route.query.token.toString()
      );

      store.commit(
        'WARN_BEFORE_LEAVE',
        'Are you sure you want to leave? This will discard your video.'
      );

      this.mediaRecorder = new MediaRecorder(this.videoSrcObject);

      // event : new recorded video blob available
      this.mediaRecorder.addEventListener('dataavailable', (event) => {
        this.blobsRecorded.push(event.data);
        this.durationSeconds += 1;
        console.log(this.videoSrcObject.getAudioTracks()[0].getSettings());
      });

      // start recording with each recorded blob having 1 second video
      this.mediaRecorder.start(1000);
      this.isRecording = true;
      logDiagnosticData(
        'recording_started',
        'OK',
        this.$route.query.token.toString()
      );
    },

    stopRecording() {
      logDiagnosticData(
        'stop_recording_requested',
        'OK',
        this.$route.query.token.toString()
      );
      this.mediaRecorder.stop();

      if (this.blobsRecorded.length === 0) {
        return;
      }

      // Sometimes in iOS Safari, Blobs (especially the first Blob in the blobsRecorded Array)
      // have empty 'type' attributes (e.g. '') so we need to find a Blob that has a defined 'type'
      // attribute in order to determine the correct MIME type.
      const mimeType = this.blobsRecorded.find(
        (blob) => blob.type?.length > 0
      ).type;

      this.outputBlob = new Blob(this.blobsRecorded, { type: mimeType });

      let outputBlobUrl = URL.createObjectURL(this.outputBlob);

      let blobId = outputBlobUrl.split('/')[3];

      localforage
        .setItem(blobId, this.outputBlob)
        .then(() => {
          console.log(`Saved ${blobId} to cache`);
        })
        .catch(function (err) {
          // This code runs if there were any errors
          console.log(err);
        })
        .finally(() => {
          this.outputBlobUrl = outputBlobUrl;
        });
      logDiagnosticData(
        'recording_stopped',
        'OK',
        this.$route.query.token.toString()
      );
    },

    toggleRecording() {
      if (!this.isRecording) {
        this.startRecording();
        return;
      }

      if (this.isRecording) {
        if (this.blobsRecorded.length === 0) {
          return;
        }

        if (this.durationSeconds < 10) {
          // this is a bit gammy, so more of a stop-gap, but people are only doing these short videos for testing purposes, so I don't think they'll mind too much.
          // We have this because short videos are erroring at the transcoding stage.
          alert(
            'Video must be at least 10 seconds long. Recording will continue.'
          );
          return;
        }

        this.isRecording = false;
        this.stopRecording();
      }
    },

    stopTracks() {
      if (!this.videoSrcObject) {
        return;
      }

      // Stops the camera / microphone from being active:
      this.videoSrcObject.getTracks().forEach(function (track) {
        track.stop();
      });
    },

    toggleMenu() {
      this.showMenuOverlay = !this.showMenuOverlay;
    },

    displayNotification(notificationMessage) {
      this.showNotification = true;
      this.notificationMessage = notificationMessage;

      this.notificationTimeouts.forEach(function (timeout) {
        clearTimeout(timeout);
      });

      let timeout = setTimeout(() => (this.showNotification = false), 30000);
      this.notificationTimeouts.push(timeout);
    },

    renderMicrophoneMessage(deviceName) {
      if (!deviceName.toLowerCase().includes('microphone')) {
        return `Using ${deviceName} microphone`;
      }

      return `Using ${deviceName}`;
    },

    exit() {
      if (this.confirmLeave()) {
        this.stopTracks();
        if (screenfull.isEnabled && screenfull.isFullscreen) {
          screenfull.exit();
        }

        this.$router.replace({
          replace: true,
          name: 'VideoRecordLandingView',
          query: {
            token: this.$route.query.token,
            context: this.$route.query.context,
            nb: this.$route.query.nb,
          },
        });
      }
    },

    upload() {
      const prefix = 'VideoRecordingMetaData';
      const id = uuidv4();
      const expires = Date.now() + 172800; // 48 hours

      const state = JSON.stringify({
        metaData: {
          id: `${prefix}#${uuidv4()}`,
        },
        expires,
      });

      localStorage.setItem(id, state);

      // Stops the camera / microphone from being active:
      this.stopTracks();

      if (screenfull.isEnabled && screenfull.isFullscreen) {
        screenfull.exit();
      }

      store.commit('OK_TO_LEAVE');

      router.push({
        path: `/video/new-upload-recording/${encodeURIComponent(
          this.outputBlobUrl
        )}`,

        query: {
          token: this.$route.query.token,
          uploadType: 'web',
        },
      });
    },
  },
});
</script>
