import LocaleMap from '@/lang/interface';

const en: LocaleMap = {
  success: 'Success',
  or: 'or',
  uploadMore: 'Upload more',
  videoUploadTokenInvalid:
    "This link or QR code has expired. You'll need to generate a new one on Steplab.",
  unsupportedDevice:
    'Steplab Record does not support your device at the moment. We are working to add support for more devices. For further information, you can contact us at: hello@steplab.co',
  webviewDetected:
    "Steplab Record won't work if you inside another app for security reasons - if you are viewing this page inside a QR code scanner app, please copy the link and paste it into your normal browser. For help, you can email us at: hello@steplab.co",
};

export default en;
