<template>
  <button
    class="takePhotoButton"
    :class="{ 'rounded-full': !isRecording }"
    type="button"
  ></button>
</template>

<script>
export default {
  name: 'RecordVideoButton',
  props: {
    isRecording: Boolean,
  },
};
</script>

<style scoped>
.outer-circle {
  box-shadow: 0 0 0 4px white;
}

.takePhotoButton {
  left: calc(50% - 25px);
  top: calc(50% - 25px);
  width: 50px;
  height: 50px;
  background-color: red;
}

#takePhotoButton:active {
  background-color: #fff;
}

button {
  outline: none;
  position: absolute;
  color: white;
  display: block;
  opacity: 1;
  background: transparent;
  border: solid 2px #fff;
  padding: 0;
  text-shadow: 0px 0px 4px black;
  background-position: center center;
  background-repeat: no-repeat;
  pointer-events: auto;
  z-index: 2;
}
</style>
