<template>
  <div
    class="absolute top-3 left-1/2 z-40 m-auto h-full w-11/12 -translate-x-1/2 text-white md:w-auto"
  >
    <div class="rounded bg-black p-2 text-lg text-light-grey opacity-80">
      <span>
        {{ notificationMessage }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoRecordWebNotification',
  props: {
    notificationMessage: String,
  },
};
</script>
