import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-10 mt-4 text-center text-xl" }
const _hoisted_2 = { class: "mb-1 flex justify-between" }
const _hoisted_3 = { class: "text-base font-medium" }
const _hoisted_4 = { class: "text-sm font-medium" }
const _hoisted_5 = { class: "bg-gray-200 dark:bg-gray-700 h-2.5 w-full rounded-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.activityWord), 1),
      _createElementVNode("span", _hoisted_4, _toDisplayString(parseInt(_ctx.progressPercentage.toString())) + "%", 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", {
        class: "h-2.5 rounded-full bg-yellow",
        style: _normalizeStyle(_ctx.widthStyle)
      }, null, 4)
    ])
  ]))
}