<template>
  <div>
    <BaseContentCard>
      <CheckCircleIcon class="m-auto w-24 text-blue" />

      <p class="mb-2 text-center font-bold">
        Success! Your recording has uploaded to Steplab.
      </p>

      <p class="mb-2 text-center"></p>

      <p class="mb-2 text-center">
        You can access all your videos from your home page. You can now safely
        close this page or record another video.
      </p>

      <div class="mb-4 mt-6 text-center">
        <router-link
          :to="{
            name: 'VideoRecordLandingView',
            query: {
              token: this.$route.query.token,
              context: this.$route.query.context,
              nb: true,
            },
          }"
        >
          <base-button class="border border-dark-grey bg-white text-dark-grey">
            <template v-slot:text>Record more</template>
          </base-button>
        </router-link>
      </div>
    </BaseContentCard>
  </div>
</template>

<script>
import BaseContentCard from '@/components/BaseContentCard.vue';
import { defineComponent } from 'vue';
import BaseButton from '@/components/BaseButton';
import { CheckCircleIcon } from '@heroicons/vue/24/solid';

export default defineComponent({
  name: 'UploadCompleteView',
  components: {
    BaseContentCard,
    BaseButton,
    CheckCircleIcon,
  },

  beforeMount() {
    this.tokenId = this.$route.query.token;
  },

  data() {
    return {
      tokenId: null,
    };
  },

  methods: {
    async returnToLanding(message) {
      if (message) {
        alert(message);
      }

      await this.$router.replace({
        replace: true,
        name: 'VideoRecordLandingView',
        query: {
          token: this.$route.query.token,
          context: this.$route.query.context,
          nb: this.$route.query.nb,
        },
      });
    },
  },
});
</script>
