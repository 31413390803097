export default async function callWithRetry(fn, depth = 0) {
  const wait = (ms) => new Promise((res) => setTimeout(res, ms));

  try {
    return await fn();
  } catch (error) {
    if (depth > 500) {
      throw error;
    }
    await wait(1.2 ** depth * 10);

    return callWithRetry(fn, depth + 1);
  }
}
