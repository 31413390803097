<template>
  <main
    :class="{ hidden: hideTopBarOnMobile, 'xl:flex': hideTopBarOnMobile }"
    class="md:h14 flex h-12 w-full flex-row items-center border-b border-border-grey bg-white lg:flex lg:h-16"
  >
    <logo></logo>
  </main>
</template>

<script>
import Logo from '@/components/SteplabLogo';

export default {
  name: 'LogoBar',
  components: { Logo },
  computed: {
    hideTopBarOnMobile() {
      return this.$route.meta.hideTopBarOnMobile;
    },
  },
};
</script>
