<template>
  <button
    class="flex inline-flex max-w-full flex-row items-center justify-center rounded-md py-2 px-2 font-bold text-white hover:opacity-90"
  >
    <span class="mr-1 w-2/12">
      <slot name="icon"> </slot>
    </span>

    <span class="">
      <slot name="text"> </slot>
    </span>
  </button>
</template>
