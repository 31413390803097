export default {
  namespaced: true,

  state: {
    selectedAudioDevice: null,
    selectedVideoDevice: null,
  },

  mutations: {
    SET_MICROPHONE(state, value) {
      state.selectedAudioDevice = value;
    },

    SET_CAMERA(state, value) {
      state.selectedVideoDevice = value;
    },
  },

  actions: {},
};
